import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import RegisterPassanger from "./RegisterPassanger";
import Login from "./Login";
import NotFound from "./NotFound";
import PassangersPanel from "./PassangersPanel";
import Contact from "./Contact";
import GuzoMap from "./GuzoMap";
function App() {
  return (
    <div>
      <Router>
        <GuzoMap />
        <Routes>
          <Route
            path="/Contact"
            element={
              <>
                <Contact />
              </>
            }
          />{" "}
          <Route
            path="/"
            element={
              <>
                <PassangersPanel />
              </>
            }
          />{" "}
          <Route
            path="/PassangersPanel"
            element={
              <>
                <PassangersPanel />
              </>
            }
          />
          <Route
            path="/register"
            element={
              <>
                <RegisterPassanger />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <Login />
              </>
            }
          />
          <Route
            path="*"
            element={
              <>
                <NotFound />
              </>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}
export default App;
