import { Box } from "@mui/material";
import React from "react";

function Contact() {
  return (
    <div style={{ padding: "20px" }}>
      <Box>
        Tel = <a href="tel:+251922112480">+251922112480</a>
      </Box>
      <Box sx={{ padding: "20px 0" }}>
        WhatsApp = <a href="https://wa.me/+251922112480">Click here</a>
      </Box>
      <Box>
        {" "}
        Telegram =<a href="https://t.me/+251922112480">Click here</a>
      </Box>
    </div>
  );
}

export default Contact;
