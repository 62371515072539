import React, { useState, useEffect } from "react";
import { TextField, Button, Container } from "@mui/material";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import MapImg from "./Images/ImgMap.png";
function LoginForm() {
  let PATH = process.env.REACT_APP_PASSANGERS_SERVER,
    Navigate = useNavigate();
  const [Token, setToken] = useState(localStorage.getItem("token"));
  const [Processing, setProcessing] = useState(false);
  const [loginData, setLoginData] = useState({
    identifier: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    identifier: "",
    password: "",
  });

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        setProcessing(true);
        const response = await axios
          .post(PATH + "/login", loginData)
          .then((responce) => {
            setProcessing(false);
            console.log("axios login", responce.data);
            let { data, userProfile, token } = responce.data;
            console.log(
              "data",
              data,
              "userProfile",
              userProfile,
              "token",
              token
            );
            // return;
            if (data == "success") {
              setToken(token);
              localStorage.setItem(
                "userPassangerProfile",
                JSON.stringify(userProfile[0])
              );
              localStorage.setItem("token", token);
              Navigate("/PassangersPanel");
            }
            if (data == "Phone number / email is not registered.") {
              // alert("This " + data);
              setloginError(<h4 style={{ color: "red" }}>{data}</h4>);
            }
          })
          .catch((error) => {
            setProcessing(false);
            console.log(error);
          });
        // console.log("Login successful");
        // Handle the response from the server
      } catch (error) {
        console.error("Login failed", error);
        // Handle error response from the server
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { identifier: "", password: "" };

    if (!loginData.identifier) {
      newErrors.identifier = "Please enter your phone number or email";
      isValid = false;
    }

    if (!loginData.password) {
      newErrors.password = "Please enter your password";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  const [loginError, setloginError] = useState("");
  useEffect(() => {
    if (Token !== null) Navigate("/passangersPanel");
  }, [Token]);
  return (
    <div
      style={{
        position: "absolute",
        bottom: "70px",
        left: "49%",
        transform: "translateX(-50%)",
        width: "100%",
        maxWidth: "300px",
        backgroundColor: "white",
        padding: "20px",
        margin: "auto",
      }}
    >
      <h3 style={{ textAlign: "center" }}>Passangers login form</h3>
      <form onSubmit={handleSubmit} style={{ margin: "auto" }}>
        <TextField
          label="Phone Number or Email"
          name="identifier"
          value={loginData.identifier}
          onChange={handleChange}
          error={!!errors.identifier}
          helperText={errors.identifier}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          name="password"
          type="password"
          value={loginData.password}
          onChange={handleChange}
          error={!!errors.password}
          helperText={errors.password}
          fullWidth
          margin="normal"
        />
        <br />
        <br />
        <div>
          Don't you have account?{" "}
          <span
            onClick={() => Navigate("/register")}
            style={{ color: "blue", cursor: "pointer" }}
          >
            Register Here
          </span>
        </div>
        <br />
        {!Processing ? (
          <Button fullWidth type="submit" variant="contained" color="primary">
            Login
          </Button>
        ) : (
          <Button fullWidth disabled variant="contained">
            {" "}
            Processing... {""}
            <CircularProgress color="primary" />
          </Button>
        )}
      </form>
      {loginError}
    </div>
  );
}

export default LoginForm;
