import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
let PATH = process.env.REACT_APP_PASSANGERS_SERVER;
function RegisterPassanger() {
  const [Processing, setProcessing] = useState(false);
  const [UserData, setUserData] = useState({
    token: null,
    userProfile: null,
  });
  let Navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [verifyProcess, setVerifyProcess] = useState(false);
  const handleDriverRegistrationSubmit = (e) => {
    e.preventDefault();

    // Perform validation
    let isValid = true;
    if (name === "") {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }
    if (email === "") {
      setEmailError("Email is required");
      isValid = false;
    } else if (!/^\S+@\S+$/.test(email)) {
      setEmailError("Invalid email format");
      isValid = false;
    } else {
      setEmailError("");
    }
    if (phone === "") {
      setPhoneError("Phone is required");
      isValid = false;
    } else if (!/^\+?\d+$/.test(phone)) {
      setPhoneError("Invalid phone format");
      isValid = false;
    } else {
      setPhoneError("");
    }

    // If form is valid, handle form submission
    if (isValid) {
      console.log({ name, email, phone });
      // Handle form submission data here
      setProcessing(true);
      seterrorOnRegistration("");
      // alert("PATH == " + PATH);
      axios
        .post(PATH + "/registerUsers", { name, email, phone })
        .then((Responces) => {
          setProcessing(false);
          let { data, token, userProfile } = Responces.data;
          console.log("data", data);
          // return;
          // let myData = data.data.data;
          if (data == "alreadyRegistered") {
            seterrorOnRegistration(
              <h4 style={{ color: "red" }}>
                your phone or email is registered before
              </h4>
            );
          } else if (data == "success") {
            setUserData({ token, userProfile: userProfile[0] });
            setVerifyProcess(true);
            // localStorage.setItem(
            //   "userPassangerProfile",
            //   JSON.stringify(userProfile[0])
            // );
            // localStorage.setItem("token", token);
            // Navigate("/PassangersPanel");
          } else if (data == "Data pin updated") {
            setVerifyProcess(true);

            setUserData({ token, userProfile: userProfile[0] });
            setVerifyProcess(true);
            localStorage.setItem(
              "userPassangerProfile",
              JSON.stringify(userProfile[0])
            );

            alert(
              "we have set you 6 digit number to your mobile please see it and insert it in text fieled"
            );
          } else if (data == "Data not found") {
            alert(
              "This data is not found this happened because you have registered previously and now you have changed either phone or email. so please use correct email and phone"
            );
          }
        })
        .catch((error) => {
          console.log(error);
          setProcessing(false);
        });
    }
  };
  const [errorOnRegistration, seterrorOnRegistration] = useState("");
  let VerifyPassangersRegistrationPhoneNumber = (e) => {
    e.preventDefault();
    console.log("sixDigitValue", sixDigitValue);
    let { token, userProfile } = UserData;
    let data = { token, sixDigitValue };

    axios
      .post(PATH + "/VerifyPassangersRegistrationPhoneNumber", data)
      .then((Responces) => {
        console.log("Responces", Responces.data);
        let { Message } = Responces.data;
        if (Message == "User registered correctly") {
          localStorage.setItem(
            "userPassangerProfile",
            JSON.stringify(userProfile)
          );
          localStorage.setItem("token", token);
          Navigate("/PassangersPanel");
        } else if (Message == "You have entered wrong pin code") {
        } else {
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const [sixDigitValue, setSixDigitValue] = useState("");
  return (
    <div
      style={{
        position: "absolute",
        bottom: "70px",
        left: "49%",
        transform: "translateX(-50%)",
        width: "100%",
        maxWidth: "300px",
        backgroundColor: "white",
        padding: "20px",
        margin: "auto",
      }}
    >
      <h3 style={{ textAlign: "center" }}>Passangers registration form</h3>

      {!verifyProcess ? (
        <form
          onSubmit={handleDriverRegistrationSubmit}
          style={{ margin: "auto", display: "flex", flexDirection: "column" }}
        >
          <br />
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={!!nameError}
            helperText={nameError}
          />{" "}
          <br />
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!emailError}
            helperText={emailError}
          />{" "}
          <br />
          <TextField
            label="Phone"
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            error={!!phoneError}
            helperText={phoneError}
          />{" "}
          <br />
          <h4>
            If you have account{" "}
            <span
              onClick={() => Navigate("/login")}
              style={{ color: "blue", cursor: "pointer" }}
            >
              click here to log in
            </span>
          </h4>
          <br />
          {!Processing ? (
            <Button type="submit" variant="contained" color="primary">
              Register
            </Button>
          ) : (
            <Button variant="contained" disabled>
              Processing..
              <CircularProgress color="primary" />
            </Button>
          )}
          {errorOnRegistration}
        </form>
      ) : (
        <form
          style={{ display: "flex", flexDirection: "column" }}
          onSubmit={VerifyPassangersRegistrationPhoneNumber}
        >
          <TextField
            sx={{ width: "100%", margin: "20px 0" }}
            onChange={(e) => {
              setSixDigitValue(e.target.value);
            }}
            value={sixDigitValue}
            label="Enter 6 digis number from your sms"
          />
          <Button type="submit" fullWidth variant="contained">
            Verify
          </Button>
        </form>
      )}
    </div>
  );
}
export default RegisterPassanger;
