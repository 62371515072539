import React from "react";
import MapContainer from "./PassangersMapContainer";

function PassangersPanel() {
  return (
    <div>
      <MapContainer />
    </div>
  );
}

export default PassangersPanel;
