import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function MyComponent() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Perform any necessary operations related to the DOM or element manipulation here
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <div>
      {/* <button onClick={handleClick}>Show Snackbar</button> */}
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
          This is a Snackbar message.
        </Alert>
      </Snackbar>
    </div>
  );
}

export default MyComponent;
