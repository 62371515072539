import React, { useEffect } from "react";
import { ConsumeContext } from "./StateProvider";
import { Box, Typography } from "@mui/material";

function GuzoMap() {
  const loadMap = async () => {
    const script = document.createElement("script");
    script.id = "mapScript";
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  };
  let { lat, setLat, lng, setLng, openSnackBar, setopenSnackBar } =
    ConsumeContext();
  useEffect(() => {
    const showPosition = (position) => {
      const { latitude, longitude } = position.coords;
      setLat(latitude);
      setLng(longitude);
    };

    const showError = (error) => {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          setopenSnackBar({
            Message: (
              <Typography color={"error"}>
                Please turn on your location and grant permission to this app.
                Thank you
              </Typography>
            ),
            Open: true,
          });
          break;
        case error.POSITION_UNAVAILABLE:
          alert("Location information is unavailable.");
          break;
        case error.TIMEOUT:
          alert("The request to get user location timed out.");
          break;
        case error.UNKNOWN_ERROR:
          alert("An unknown error occurred.");
          break;
      }
    };
    ///////////////////
    if ("geolocation" in navigator) {
      console.log("Geolocation is supported by this browser.");
      if (navigator.permissions) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then((permissionStatus) => {
            if (permissionStatus.state === "granted") {
              console.log("Location services are enabled.");
              // Proceed with finding the nearest taxi
            } else if (permissionStatus.state === "prompt") {
              console.log("Location permission prompt is shown.");
              // You can choose to handle this case differently, based on your application's needs
            } else if (permissionStatus.state === "denied") {
              console.log("Location services are disabled.");
              // You can inform the user that location services are disabled and provide guidance on enabling them
              setopenSnackBar({
                Message: (
                  <Typography color="error">
                    please turn on your location
                  </Typography>
                ),
                Open: true,
              });
            }
          });
      } else {
        console.log(
          "Permission API is not supported. Cannot determine location permission status."
        );
        // Handle the case where the browser does not support the Permissions API
      }
    } else {
      alert("Geolocation is not supported by this browser.");
      console.log("Geolocation is not supported by this browser.");
      // Handle the case where geolocation is not supported
    }
    //////////////////////

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      console.log("Geolocation is not supported.");
    }
  }, []);
  useEffect(() => {
    if (lat && lng) {
      // document.getElementById("map").innerHTML = "";
      let mapScript = document.getElementById("mapScript");
      if (mapScript) window.initMap(); //init map
      else loadMap(); // no need of init map
    }
  }, [lat, lng]);
  window.initMap = () => {
    const map = new window.google.maps.Map(
      document.getElementById("passangersMap"),
      {
        center: { lat, lng },
        zoom: 17,
        mapTypeId: "roadmap",
      }
    );
    let guzoMarker = new window.google.maps.Marker({
      animation: window.google.maps.Animation.BOUNCE,
      position: { lat, lng },
      map: map,
      title: "Marker",
    });
    ////////////////////
    let infowindow = new window.google.maps.InfoWindow({
      content: "you are here!",
    });
    ////////////////////////////
    infowindow.open(map, guzoMarker);
  };
  return (
    <Box
      id="passangersMap"
      style={{ height: "100Vh", width: "100vw", position: "static !important" }}
    ></Box>
  );
}
export default GuzoMap;
