import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import {
  Alert,
  Snackbar,
  Box,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DropdownMenu from "./DropdownMenu";
import SnackBar from "./SnackBar";
import { ConsumeContext } from "./StateProvider";
let PATH = process.env.REACT_APP_PASSANGERS_SERVER;
const MapContainer = () => {
  let { lat, lng, openSnackBar, setopenSnackBar } = ConsumeContext();
  const [process, setprocess] = useState(false);
  let Navigate = useNavigate();
  const [UserStatus, setUserStatus] = useState({ status: "waitting ..." });
  const [Form, setForm] = useState({ display: false });

  useEffect(() => {
    if (lat !== null && lng !== null) {
      // navigator.geolocation.getCurrentPosition((position) => {
      //   const { latitude, longitude } = position.coords;
      //   setLat(latitude);
      //   setLng(longitude);
      // });

      checkPassangersStatus();
      checkTimeInterval = setInterval(() => {
        checkPassangersStatus();
      }, 2000);
      return () => {
        clearInterval(checkTimeInterval);
      };
    }
  }, [lat, lng]);
  let checkTimeInterval = "";

  let token = localStorage.getItem("token");
  if (
    token == null ||
    token == "null" ||
    token == undefined ||
    token == "undefined"
  ) {
    Navigate("/");
  }
  let sendMyLocationToServer = async (e) => {
    setprocess(true);
    e.preventDefault();
    axios
      .post(PATH + "/sendMyLocationToServer", { lat, lng, token, MyLocation })
      .then((responce) => {
        setTimeout(() => {
          setprocess(false);
        }, 2000);
        let { data, result } = responce.data;
        console.log("responce", responce.data);
        if (data == "inserted") {
          setForm({ display: false });
          // alert("u r registered please wait");
        } else if (data == "on service") {
          alert("on service");
        }
      })
      .catch((error) => {
        setprocess(false);
        console.log("error", error);
      });
  };
  let checkPassangersStatus = async () => {
    // alert("token" + token);
    if (token == null) {
      Navigate("/login");
      localStorage.clear();
      // clearInterval(checkInterval);
    }
    await axios
      .post(PATH + "/checkPassangersStatus", { token })
      .then((Responces) => {
        console.log("Responces.data", Responces.data);
        let myData = Responces.data.data,
          results = "";
        if (Responces.data.result) results = Responces?.data?.result[0];
        // console.log("results", results);
        if (myData == "login first") {
          localStorage.removeItem("token");
          Navigate("/");
        } else if (myData == "on service") {
          setUserStatus({ status: myData, results: results });
        } else if (myData == "no service") {
          setUserStatus({ status: "user can call taxi", results: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [MyLocation, setMyLocation] = useState({});
  let collectMyLocation = (e) => {
    setMyLocation((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };
  // waiting to location
  let showModalToCancilation = (e) => {
    setOpenOrderCancilationModal(true);
    console.log(e);
  };
  let sendCancilationRequest = (e) => {
    console.log("token, reason ", token, reason);
    setprocess(true);
    axios
      .post(PATH + "/cancelPasangersRequest", { token, reason })
      .then((Responce) => {
        setprocess(false);
        console.log(Responce.data.data);
      })
      .catch((error) => {
        setprocess(false);
        console.log("error", error);
      });
  };
  const [openOrderCancilationModal, setOpenOrderCancilationModal] =
    useState(false);
  const [reason, setreason] = useState("");
  let handleReasonChange = (e) => {
    setreason(e.target.value);
  };
  let confirmDeliveryByPassangers = (Status) => {
    console.log(UserStatus.results);
    let { guzoId, driversId } = UserStatus.results;
    console.log("token", token);
    // return;
    console.log(" guzoId, driversId", guzoId, token);
    axios
      .post(PATH + "/confirmDeliveryByPassangers", { guzoId, token, Status })
      .then((Responces) => {
        console.log("data", Responces.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  let handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setopenSnackBar((prevState) => {
      return {
        ...prevState,
        Open: false,
        // Message: "",
      };
    });
  };
  let userProfile = localStorage.getItem("userPassangerProfile");
  // alert("userPassangerProfile " + userProfile);
  if (!userProfile || userProfile == "") {
    Navigate("/login");
    localStorage.clear();
  }
  return (
    <div>
      <Snackbar
        sx={{ maxWidth: "300px", width: "80%", margin: "100px auto" }}
        open={openSnackBar.Open}
        autoHideDuration={3000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          // severity="success"
          color="success"
          sx={{ width: "100%" }}
        >
          {openSnackBar.Message}
        </Alert>
      </Snackbar>
      <Modal
        open={openOrderCancilationModal}
        onClose={() => setOpenOrderCancilationModal(false)}
      >
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
            display: "flex",
            flexDirection: "column",
            width: "80%",
            maxWidth: "350px",
          }}
        >
          <h4> Can you tell us why you cancel it? </h4>
          <Select
            value={reason}
            onChange={handleReasonChange}
            style={{ width: "100%", marginBottom: "16px" }}
          >
            <MenuItem value="driver-late">Driver is late</MenuItem>
            <MenuItem value="just-trying">Just for trying</MenuItem>
            <MenuItem value="changed-mind">Changed my mind</MenuItem>
            <MenuItem value="wrong-destination">Wrong Destination</MenuItem>
          </Select>
          <Button
            variant="contained"
            onClick={() => {
              setOpenOrderCancilationModal(false);
              sendCancilationRequest();
            }}
          >
            Submit
          </Button>
        </Box>
      </Modal>
      {/* <div id="map" style={{ height: "100%", width: "100%" }}></div> */}
      <Box
        style={{
          position: "absolute",
          bottom: "70px",
          left: "49%",
          transform: "translateX(-50%)",
          width: "100%",
          maxWidth: "300px",
          backgroundColor: "white",
          padding: "20px",
          margin: "auto",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <div>
            Dear{" "}
            {" " +
              JSON.parse(localStorage.getItem("userPassangerProfile"))
                ?.fullName}
            {" , "}
            welcome to penta orders
          </div>
          <DropdownMenu />
        </Box>
        {openSnackBar.Message}
        {UserStatus.status == "waitting ..." ? (
          <div>Waitting ...</div>
        ) : UserStatus.status == "user can call taxi" ? (
          !Form.display && (
            <Box>
              {!process ? (
                <Button
                  fullWidth
                  onClick={() => setForm({ display: true })}
                  variant="contained"
                  color="primary"
                >
                  Call taxi
                </Button>
              ) : (
                <Button fullWidth disabled variant="contained">
                  Processing ...{" "}
                </Button>
              )}
            </Box>
          )
        ) : (
          <>
            {UserStatus?.results?.status == "requestedByPassenger" ? (
              <Box>
                <h3>waiting for driver's responce ... </h3>
                <Button
                  onClick={showModalToCancilation}
                  variant="contained"
                  color="warning"
                >
                  Cancel this request?
                </Button>
              </Box>
            ) : UserStatus.results.status == "journeyStarted" ? (
              <Box>
                <Box>You have started journey, have a nice jourey ... </Box>
                <Box>
                  <div>Driver name: {UserStatus.results.DriversFullName}</div>
                  <br />

                  <div>
                    Driver Phone:
                    <a href={"tel:" + UserStatus.results.phoneNumber}>
                      {UserStatus.results.phoneNumber}
                    </a>
                  </div>
                  <div>Plate Number: {UserStatus.results.plateNumber}</div>
                </Box>
              </Box>
            ) : UserStatus.results.status == "acceptedByDriver" ? (
              <Box>
                <Box>Driver is coming to you</Box>
                <Box>
                  <div>Driver name: {UserStatus.results.DriversFullName}</div>
                  <br />
                  <div>
                    Driver Phone:
                    <a href={"tel:" + UserStatus.results.phoneNumber}>
                      {UserStatus.results.phoneNumber}
                    </a>
                  </div>
                  <div>Plate Number: {UserStatus.results.plateNumber}</div>
                </Box>
                <Button>Please wait 2 munites .... </Button>
                <Button
                  fullWidth
                  onClick={showModalToCancilation}
                  color="error"
                  variant="contained"
                >
                  Cancel request
                </Button>
              </Box>
            ) : UserStatus.results.status == "journeyEnded" ? (
              <Box>
                <div> You are arrived in your destination. Thank you.</div>
                <br />
                <Button
                  onClick={() =>
                    confirmDeliveryByPassangers(UserStatus.results.status)
                  }
                  fullWidth
                  variant="contained"
                  color="warning"
                >
                  Confirm
                </Button>
              </Box>
            ) : UserStatus.results.status == "canceledByDriver" ? (
              <Box>
                <div>
                  {" "}
                  Sorry, Driver have canceled your request. please search other
                  drivers again. Thank you.
                </div>
                <br />
                <Button
                  onClick={() =>
                    confirmDeliveryByPassangers(UserStatus.results.status)
                  }
                  fullWidth
                  variant="contained"
                  color="warning"
                >
                  Okay
                </Button>
              </Box>
            ) : (
              <h4>Next step will be done here ...</h4>
            )}
          </>
        )}
        {Form.display && (
          <Box>
            <form
              onSubmit={sendMyLocationToServer}
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px 0",
              }}
            >
              {/* <h4>Form to register user</h4> */}
              <TextField
                onChange={collectMyLocation}
                name="standingPoint"
                required
                label="Standing Point"
              />
              <br />
              <TextField
                onChange={collectMyLocation}
                name="destinationPoint"
                required
                label="Destination Point"
              />
              <br />
              <Box sx={{ display: "flex" }}>
                <Button type="submit" variant="contained">
                  Send Request
                </Button>
                <Button
                  onClick={() => {
                    setForm({ display: false });
                  }}
                  color="warning"
                  sx={{ marginLeft: "10px" }}
                  variant="contained"
                >
                  Cancel
                </Button>
              </Box>
            </form>
          </Box>
        )}
      </Box>
    </div>
  );
};
export default MapContainer;
