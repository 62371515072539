import React, { createContext, useContext, useState } from "react";
let GuzoContext = createContext();
function StateProvider({ children }) {
  const [openSnackBar, setopenSnackBar] = useState({
    Open: false,
    Message: "",
  });
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  return (
    <GuzoContext.Provider
      value={{ openSnackBar, setopenSnackBar, lat, setLat, lng, setLng }}
    >
      {children}
    </GuzoContext.Provider>
  );
}
export let ConsumeContext = () => useContext(GuzoContext);
export default StateProvider;
